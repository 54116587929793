import { useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios, { endpoints } from 'src/utils/axios';
//
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';
import { ActionMapType, AuthStateType, AuthUserType } from '../../types';

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const res = await axios.get(
          'https://crm-api.produktiv.agency/api/users/me?cid=zxcvb&populate=*',
          {
            headers: {
              'Powered-By': 'Produktiv',
            },
          },
        );

        const user = res.data;

        dispatch({
          type: Types.INITIAL,
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  //
  // CREATE CONTACT
  const createContact = useCallback(async (dataContact: any) => {
    const res = await axios.post(
      'https://crm-api.produktiv.agency/api/leads/?cid=123456&test=qwer',
      { data: dataContact },
      {
        headers: {
          Origin: 'cpp.ca',
          'Powered-By': 'Produktiv',
        },
      },
    );

    const { jwt, user } = res.data;

    setSession(jwt);

    dispatch({
      type: Types.LOGIN,
      payload: {
        user,
      },
    });
  }, []);

  //
  // LOGIN
  const login = useCallback(async (email: string, password: string) => {
    const formData = new FormData();

    formData.append('identifier', email);

    formData.append('password', password);

    const res = await axios.post(
      'https://crm-api.produktiv.agency/api/auth/local?cid=zxcvb',
      formData,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Origin: 'produktiv.agency',
          'Powered-By': 'Produktiv',
        },
      },
    );

    const { jwt, user } = res.data;

    setSession(jwt);

    dispatch({
      type: Types.LOGIN,
      payload: {
        user,
      },
    });
    return res.data;
  }, []);

  const loginGoogle = useCallback(async (search: string) => {
    const res = await axios.get(
      `https://crm-api.produktiv.agency/api/auth/google/callback${search}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Origin: 'produktiv.agency',
          'Powered-By': 'Produktiv',
        },
      },
    );

    const { jwt, user } = res.data;

    setSession(jwt);

    dispatch({
      type: Types.LOGIN,
      payload: {
        user,
      },
    });
    return res.data;
  }, []);

  // REGISTER
  const register = useCallback(
    async (
      email: string,
      password: string,
      firstName: string,
      lastName: string,
    ) => {
      const data = {
        email,
        password,
        firstName,
        lastName,
      };

      const formData = new FormData();
      formData.append('email', email);
      formData.append('password', password);
      formData.append('username', email);
      formData.append('fullName', firstName);

      const res = await axios.post(
        'https://crm-api.produktiv.agency/api/auth/local/register?cid=zxcvb',
        formData,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Origin: 'produktiv.agency',
            'Powered-By': 'Produktiv',
          },
        },
      );

      const { jwt, user } = res.data;

      sessionStorage.setItem(STORAGE_KEY, jwt);

      dispatch({
        type: Types.REGISTER,
        payload: {
          user,
        },
      });
    },
    [],
  );

    // FORGOT PASSWORD
    const forgotPassword = useCallback(
      async (email: any) => {
  
        await axios.post(
          'https://crm-api.produktiv.agency/api/auth/local/forgot-password?cid=zxcvb',
          { email },
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Origin: 'produktiv.agency',
              'Powered-By': 'Produktiv',
            },
          },
        );
      },
      [],
    );

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      logout,
      createContact,
      loginGoogle,
      forgotPassword
    }),
    [login, logout, register, state.user, status, createContact, loginGoogle, forgotPassword],
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}
