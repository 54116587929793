import { useCallback, useEffect, useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
// routes
import { useRouter } from 'src/routes/hooks';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

type Props = {
  image?: string;
  children: React.ReactNode;
};

export default function AuthModernLayout({ children, image }: Props) {
  const router = useRouter();
  const upMd = useResponsive('up', 'md');

  const { user } = useAuthContext();

  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (user && !user.account_id) {
      router.replace('/onboarding');
    } else if (user) {
      router.replace('/dashboard');
    } else {
      setChecked(true);
    }
  }, [user, router]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: '50%',
        '@media (max-width: 900px)': {
          maxWidth: '480px',
        },
        px: { xs: 2, md: 8 },
      }}
    >
      <Logo
        sx={{
          mt: { xs: 2, md: 8 },
          mb: { xs: 10, md: 8 },
        }}
      />

      <Card
        sx={{
          py: { xs: 5, md: 0 },
          px: { xs: 3, md: 0 },
          boxShadow: { md: 'none' },
          overflow: { md: 'unset' },
          bgcolor: { md: 'background.default' },
        }}
      >
        {children}
      </Card>
    </Stack>
  );

  const renderSection = (
    <Stack
      flexGrow={1}
      sx={{
        position: 'relative',
        bgcolor: (theme) =>
          theme.palette.mode === 'light' ? 'grey.100' : 'background.default',
      }}
    >
      <Box
        component="img"
        alt="auth"
        src={image || '/assets/images/login/login-img-1.svg'}
        sx={{
          top: '50%',
          left: '50%',
          objectFit: 'contain',
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          width: 'calc(100% - 32px)',
          height: 'calc(65% - 32px)',
        }}
      />
    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
        position: 'relative',
        '&:before': {
          width: 1,
          height: 1,
          zIndex: -1,
          content: "''",
          position: 'absolute',
          backgroundSize: 'cover',
          opacity: { xs: 0.24, md: 0 },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundImage: 'url(/assets/images/login/login-img-1.svg)',
          filter: 'blur(10px)',
        },
      }}
    >
      {renderContent}

      {upMd && renderSection}
    </Stack>
  );
}
